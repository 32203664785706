import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';

import { USER_ID, GUEST_USER_ID, ADMIN_USER_ID } from '@prcins/constants';
import { Router, ActivatedRoute } from '@angular/router';
import { PolicyProviderService } from '../services/policy-provider.service';
import { AdminService } from '../services/admin.service';
import { INotification, LoginService } from '@prcins/utils';
import { UploadAdminDocsService } from '../services/upload-admindocs.service';
import { catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { of } from 'rxjs';

@Component({
  selector: 'prcins-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  showSpinner = false;
  showUnsupportedFile = false;
  showUnsupportedFileSize = false;
  supportedFiles = ['csv', 'CSV'];
  supportedFilesList = ".csv, .CSV";
  currentInputElement: any;
  docStatus: any;
  selectedActivity;
  notification: INotification;
  adminActivityForm: FormGroup = this.fb.group({
    activity: ['policyNumber'],
    activityInput: this.fb.array([])
  });

  superUser: boolean;
  file: any;

  policyInput: FormControl = new FormControl('', [Validators.required]);
  emailInput: FormControl = new FormControl('',
    [
      Validators.required,
      Validators.email,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')
    ]);
  uploadInput: FormControl = new FormControl('', [Validators.required]);

  arrayInput: { name: string, label: string, formControl: FormControl, controlType: string }[];



  constructor(private fb: FormBuilder,
    private policyService: PolicyProviderService,
    private loginService: LoginService,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private uploadDocsService: UploadAdminDocsService) {

    this.adminService.adminActivityError.subscribe(data => {
      this.notification = data;
    });
  }



  ngOnInit() {
    sessionStorage.removeItem(GUEST_USER_ID);
    this.selectedActivity = this.activity.value;
    this.onActivityChange();

    this.adminActivityForm.get('activity').valueChanges.subscribe(val => {
      this.selectedActivity = val;
      this.onActivityChange();
    })

    this.adminService.validateSuperAdmin().subscribe((data) => {
      this.superUser = data ? data : false;
    });

  }

  get activityInput() {
    return this.adminActivityForm.get('activityInput') as FormArray;
  }
  set activityInput(value: FormArray) { }

  get activity() {
    return this.adminActivityForm.get('activity') as FormControl;
  }

  onActivityChange() {
    switch (this.selectedActivity) {
      case "policyNumber":
        this.arrayInput = [];
        this.arrayInput.push({
          name: this.selectedActivity,
          label: "Policy Number",
          formControl: this.policyInput,
          controlType: "text"
        });

        this.activityInput.clear();
        this.arrayInput.map(({ formControl }) => { console.log(formControl); this.activityInput.push(formControl) });
        break;
      case "email":
        this.arrayInput = [];
        this.arrayInput.push({
          name: this.selectedActivity,
          label: "Email",
          formControl: this.emailInput,
          controlType: "text"
        });

        this.activityInput.clear();
        this.arrayInput.map(({ formControl }) => { console.log(formControl); this.activityInput.push(formControl) });
        break;
      case "bulkInviteUpload":
        this.arrayInput = [];
        this.arrayInput.push({
          name: this.selectedActivity,
          label: "CSV Document",
          formControl: this.uploadInput,
          controlType: "file"
        });

        this.activityInput.clear();
        this.arrayInput.map(({ formControl }) => { console.log(formControl); this.activityInput.push(formControl) });
        break;
      case "endorsementBlockUpload":
        this.arrayInput = [];
        this.arrayInput.push({
          name: this.selectedActivity,
          label: "CSV Document",
          formControl: this.uploadInput,
          controlType: "file"
        });

        this.activityInput.clear();
        this.arrayInput.map(({ formControl }) => { console.log(formControl); this.activityInput.push(formControl) });
        break


    }
  }

  // On file Select
  onFileChange(event) {
    this.file = event.target.files[0];
    this.currentInputElement = event;
  }


  onSubmit() {
    this.showSpinner = true;
    let { activity, activityInput: userInput } = this.adminActivityForm.value;
    switch (activity) {
      case 'policyNumber':
        this.policyService.getUserName(userInput[0]).subscribe(email => {
          if (email) {
            sessionStorage.setItem(USER_ID, email);
          } else {
            sessionStorage.setItem(GUEST_USER_ID, userInput[0]);
          }
          this.router.navigate(['eservice/home']);
          this.showSpinner = false;
        }, error => { this.notification = { type: 'ERROR', message: "Policy Not Available" }; }, () => { this.showSpinner = false; });
        break;
      case 'email':
        const emailAddress = userInput[0];
        this.loginService.getActiveAccount(emailAddress).subscribe(accountInfo => {
          if (accountInfo.active) {
            this.router.navigate(['eservice/admin/activity/delete'], { queryParams: { email: emailAddress } });
          } else {
            this.notification = { type: 'ERROR', message: "Email Account Not Found" };
          }
          this.showSpinner = false;
        });
        break;
      case 'bulkInviteUpload':
        this.uploadBulkInvitationDocument(activity);
        break;
      case 'endorsementBlockUpload':
        this.uploadEndorsementBlockDocument(activity);
        break;

    }

  }

  uploadBulkInvitationDocument(docType: string) {
    const fileName = this.file.name;
    let fileExtension = "";

    if (_.lastIndexOf(fileName, ".") > 0 && fileName) {
      fileExtension = fileName.substring(_.lastIndexOf(fileName, ".") + 1, fileName.length);
    } else {
      this.showSpinner = false;
      return;
    }


    if (_.includes(this.supportedFiles, fileExtension)) {
      if (this.file) {
        let fileSize = this.file.size / 1024 / 1024;

        if (fileSize > 25) {
          this.showSpinner = false;
          this.showUnsupportedFileSize = true;
          return;
        }
        let formData: FormData = new FormData();
        formData.append('file', this.file);
        formData.append('docType', docType);
        formData.append('fileExtension', fileExtension);

        this.uploadDocsService.uploadBulkInviteDocument(formData).subscribe((res) => {
          if (res.upload === 'success') {
            this.notification = {
              type: "INFO",
              message: `${docType.toUpperCase()}: File staged and will be processed`
            }
            this.docStatus = res.data;
            this.showSpinner = false;

          }
          if (res.error === 'UNSUPPORTED') {
            this.showSpinner = false;
            this.showUnsupportedFile = true;

          }
          if (res.error === 'UNSUPPORTED-SIZE') {
            this.showSpinner = false;
            this.showUnsupportedFileSize = true;

          }
          if (res.error === 'SERVER-ERROR') {
            this.showSpinner = false;
            this.notification = {
              type: "WARN",
              message: "Error Uploading the document."
            }
          }
        }, (error) => {
          this.showSpinner = false;
          this.notification = {
            type: "WARN",
            message: "Error Uploading the document."
          }
        });
      } else {
        this.showSpinner = false;
      }
    } else {
      this.showUnsupportedFile = true;
      this.showSpinner = false;
    }
  }

  uploadEndorsementBlockDocument(docType: string) {
    const fileName = this.file.name;
    let fileExtension = "";

    if (_.lastIndexOf(fileName, ".") > 0 && fileName) {
      fileExtension = fileName.substring(_.lastIndexOf(fileName, ".") + 1, fileName.length);
    } else {
      this.showSpinner = false;
      return;
    }


    if (_.includes(this.supportedFiles, fileExtension)) {
      if (this.file) {
        let fileSize = this.file.size / 1024 / 1024;

        if (fileSize > 25) {
          this.showSpinner = false;
          this.showUnsupportedFileSize = true;
          return;
        }
        const formData = new FormData();
        formData.append('file', this.file);

        this.uploadDocsService.uploadEndorsementBlockDocument(formData).pipe(
          catchError((error) => {
            // handle the error
            console.error(error);
            // return an observable with a user-facing error message
            let errMsg = "There was an error uploading the file. Possible Reason:" + error.error.messageDescription
            return of({ "errMessage": errMsg });
          })
        ).subscribe((res) => {
          if (res.message) {
            this.notification = {
              type: "INFO",
              message: res.message
            }
          }
          if (res.errMessage) {
            this.notification = {
              type: "WARN",
              message: "Error Uploading the document."
            }
          }
        });
      }
    } else {
      this.showUnsupportedFile = true;
    }
    this.showSpinner = false;
  }

  retryUpload() {
    let target = this.currentInputElement.target || this.currentInputElement.srcElement;
    target.click();
    this.showUnsupportedFile = false;
    this.showUnsupportedFileSize = false;
  }

  closeUnsupportedFilePopUp() {
    this.showUnsupportedFile = false;
    this.showUnsupportedFileSize = false;
  }

  

}
