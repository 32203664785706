<prcins-header></prcins-header>
<main class="flex justify-center w-full">
  <div class="es-container w-full flex justify-between flex-wrap">
    <section class="es-container w-full px-4">
      <h1 class="es-h1 mt-4">Disable Account</h1>
      <ng-container *ngIf="isLoading; else data">
        <prcins-card pt="pt-0">
          <div body>
            <prcins-skeleton numOfRows="5"></prcins-skeleton>
          </div>
        </prcins-card>
      </ng-container>
      <ng-template #data>
        <prcins-notification [notification]="notification"></prcins-notification>
        <prcins-card headerClass="bg-es-blue text-white">
          <span header-left>Account Info</span>
          <div body>

            <div class="flex justify-between sm:px-2">
              <span>Name</span><span class="font-bold">{{fullName}}</span>
            </div>
            <div class="flex justify-between sm:px-2">
              <span>Email</span><a class="text-es-blue-light font-bold">{{accountLookupInfo.email}}</a>
            </div>
            <div class="flex justify-between sm:px-2">
              <span>Phone Number</span><span class="font-bold">{{accountLookupInfo.phoneNumber}}</span>
            </div>
            <button class="mt-4 es-btn-primary w-full lg:w-40 text-center" (click)="onDisableAccount()"
            type="button">Disable</button>
            <hr />
           
          </div>
        </prcins-card>
        <prcins-card headerClass="text-black">


          <span class="font-whitney-semibold text-xl" header-left>Manage Policies</span>

          <div body>
            <div class="flex-auto flex justify-between mt-3 pb-2 border border-t-0 border-r-0 border-l-0">
              <span class="flex-auto lg:w-2/12"><strong>Policy Number</strong></span>
              <span class="flex-auto lg:w-2/12"><strong>Policy Type</strong></span>
              <span class="flex-auto lg:w-2/12"><strong>Purged Status</strong></span>
              <span class="flex-auto lg:w-2/12"><strong>Association Date</strong></span>
              <span class="flex-auto lg:w-2/12"><strong>Status</strong></span>
            </div>

            <div class="flex-auto flex justify-between mt-3 pb-2 border border-t-0 border-r-0 border-l-0"
              [class.border-b-0]="isLast" *ngFor="let policy of registeredPolicies; last as isLast ">
              <span class="flex-auto lg:w-2/12">{{policy.policyNumber}}</span>
              <ng-container [ngSwitch]="policy.policyType">
                <span class="flex-auto flex items-center lg:w-2/12" *ngSwitchCase="'PA'">
                  <!-- <img class="mr-4" src="assets/svgs/car-white-icon.svg"> -->
                  Auto Policy
                </span>
                <span class="flex-auto flex items-center lg:w-2/12" *ngSwitchCase="'HO'">
                  <!-- <img class="mr-4" src="assets/svgs/home-white-icon.svg"> -->
                  Home Policy
                </span>
                <span class="flex-auto flex items-center lg:w-2/12" *ngSwitchCase="'CA'">
                  <!-- <ng-container *ngIf="policy.policyInfo.productCode==='PCAT' || policy.policyInfo.productCode==='ALN_UMB' else businessAutoGroup"> -->
                  <!-- <img class="mr-2 lob-icon" src="assets/png/white umbrella icon.png" alt=""> -->
                  Umbrella Policy/Business Auto
                </span>
                <span class="flex-auto flex items-center lg:w-2/12" *ngSwitchCase="'MC'">
                  Umbrella Policy/Business Auto
                </span>
                <span class="flex-auto flex items-center lg:w-2/12" *ngSwitchCase="">
                  
                </span>
              </ng-container>
              <span class="flex-auto lg:w-2/12">{{policy.searchStatus}}</span>
              <span class="flex-auto lg:w-2/12">{{policy.associationDate | date: 'MMM d, y' }}</span>
              <span class="flex-auto lg:w-2/12">
                <prcins-round-toggle-switch [sliderValue]="policy.status === 'A'" [sliderKey]="policy.policyNumber"
                  (sliderChanged)="policyStatusChanged($event,policy.policyNumber)" [disabled]='false'>
                </prcins-round-toggle-switch>
              </span>
            </div>
          </div>
        </prcins-card>
 
      <prcins-card headerClass="text-white bg-es-gray">
        <span header-left>
          <div>Account Nickname</div>
        </span>
        <span header-right></span>
        <div body>
          <div class="flex flex-wrap lg:flex-row">
            <div class="flex flex-wrap lg:flex-col w-full lg:w-6/12">
              <div class="add-form-label">First Name</div>
              <div class="add-form-input w-full">
                <input  
                type="text" 
                [value]="accountLookupInfo.firstName" 
                (input)="accountLookupInfo.firstName = $event.target.value" 
                (blur)="saveFirstName()" 
                (keyup.enter)="saveFirstName()" 
                class="border rounded px-1"
              />
              </div>
            </div>
            <div class="flex flex-wrap lg:flex-col w-full lg:w-6/12">
              <div class="add-form-label">Last Name</div>
              <div class="add-form-input">
                <input 
                type="text" 
                [value]="accountLookupInfo.lastName" 
                (input)="accountLookupInfo.lastName = $event.target.value" 
                (blur)="saveLastName()" 
                (keyup.enter)="saveLastName()" 
                class="border rounded px-1"
              />
        
              </div>
             
            </div>
            
          </div>
        </div>
      </prcins-card>
        <div class="flex justify-between mt-4 flex-wrap-reverse">
          <button class="mt-4 es-btn-secondary w-full lg:w-40 text-center" [routerLink]="['../']">Cancel</button>
          <button class="mt-4 es-btn-primary w-full lg:w-40 text-center" (click)="onSavePolicyStatus()" type="button">Save</button>
        </div>
      </ng-template>
    </section>
  </div>
</main>